"use client";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import {
	Box,
	Modal,
	Stack,
	useMediaQuery,
	IconButton,
	Typography,
	Grid,
	Menu,
	MenuItem,
	Collapse,
	List,
	ListItemButton,
	ListItemText,
	Paper,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Link from "next/link";
import ClearIcon from "@mui/icons-material/Clear";
import { useRouter } from "next/navigation";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	borderRadius: "5px",
	boxShadow: 24,
};

function Header() {
	const [selectedTab, setSelectedTab] = useState("");
	const [showButton, setShowButton] = useState(false);
	const [borderScroll, setBorderScroll] = useState(false);
	const heads = [
		{ id: 0, heading: "ABHA", link: "/abha" },
		{ id: 1, heading: "Features", link: "/#features" },
		{ id: 2, heading: "Blogs", link: "/#blogs" },
		{ id: 3, heading: "Careers", link: "/careers" },
		{ id: 4, heading: "Contact Us", link: "/#contact" },
	];

	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("md"));
	const matchesdownMd = useMediaQuery(theme.breakpoints.down("lg"));

	const [open, setOpen] = useState(false);

	const [openDialogBox, setOpenDialogBox] = useState(false);

	const router = useRouter();

	const [openServices, setOpenServices] = useState(false);

	const handleServiceChange = (e) => {
		setOpenServices((prev) => !prev);
	};

	const handleClickOpen = () => {
		setOpenDialogBox(true);
	};
	const handleClose = () => {
		setOpenDialogBox(false);
	};

	const handleDropClose = () => {
		setAnchorEl(null);
	};

	const handleServiceClick = (item) => {
		router.push(`/services/${item}`);
		setOpen(!open);
		setOpenServices(false);
		handleDropClose();
	};

	useEffect(() => {
		const storedTab = localStorage.getItem("selectedTab");
		if (storedTab) {
			setSelectedTab(storedTab);
		}
	}, []);

	useEffect(() => {
		localStorage.setItem("selectedTab", selectedTab);
	}, [selectedTab]);

	const [anchorEl, setAnchorEl] = useState(null);
	const openMenu = Boolean(anchorEl);

	const handleOpenMenu = (event) => {
		if (anchorEl === event.currentTarget) {
			setAnchorEl(null);
		} else {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (anchorEl && !anchorEl.contains(event.target)) {
				handleCloseMenu();
			}
		};

		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [anchorEl]);

	// dashboard image
	useEffect(() => {
		const handleScroll = () => {
			const scrollY = window.scrollY;
			setShowButton(scrollY > 100);
			if (scrollY > 100) {
				setBorderScroll(true);
			} else {
				setBorderScroll(false);
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<React.Fragment>
			<Box
				id="home"
				className={`flex flex-col justify-between items-center fixed top-0 left-0 right-0 !z-[9999]  bg-white ${
					borderScroll && "border-b-[1px] border-[#004167]/20 shadow-md"
				} `}
			>
				<div className="flex flex-row justify-between p-3 px-4 sm:px-8 xl:px-16 items-center w-[100%] ">
					<div className="p-2">
						<Link href="/">
							<Image
								src="/svg/chikitsa-logo.svg"
								width={130}
								height={30}
								alt="chikitsa"
							/>
						</Link>
					</div>
					<Box
						sx={{ width: { xs: "25%", xl: "20%" } }}
						className="hidden lg:block "
					>
						<Box
							sx={{ width: "100%", height: "20px" }}
							className="flex justify-center items-center  py-4"
						>
							<IconButton aria-label="Call us">
								<PhoneIcon
									sx={{
										color: "#053C61",
										fontSize: { xs: "12px", md: "18px" },
										fontWeight: "500",
									}}
								/>
							</IconButton>
							<a
								sx={{
									fontSize: { xs: "12px", md: "12px" },
									color: "#053C61",
									fontWeight: "500",
								}}
								href="tel:+91 9509917266"
								target="_blank"
								aria-label="Call us at +91 9509917266"
							>
								Call us (+91 9509917266)
							</a>
						</Box>
					</Box>
					<div>
						<button
							className="lg:hidden"
							id="menu"
							aria-label="menu"
							onClick={() => {
								setOpen(!open);
							}}
						>
							<MenuIcon />
						</button>
						<div>
							<div
								className={`${
									open
										? "translate-y-0 opacity-100"
										: "-translate-y-full opacity-0"
								} lg:hidden bg-[#053C61] w-[100%] h-screen overflow-scroll fixed top-0 left-0 z-[999] pb-9 transition-all duration-500 ease-in-out`}
							>
								<div
									className="flex justify-end p-6 text-white cursor-pointer"
									onClick={() => {
										setOpen(!open);
									}}
								>
									<ClearIcon />
								</div>
								{/* //mobile  */}
								<ul className="flex flex-col justify-center items-center gap-8">
									<li
										className="flex mb-1 justify-center items-center "
										onClick={() => {
											setOpen(!open);
											setOpenServices(false);
											handleDropClose();
										}}
									>
										<Link
											href="/"
											className="text-[#ffffff] font-medium text-lg text-center"
										>
											Home
										</Link>
									</li>
									<List
										sx={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
										}}
									>
										<ListItemButton
											onClick={handleServiceChange}
											className="!py-[0]"
										>
											<ListItemText
												className="text-[#ffffff] font-bold text-lg text-center"
												primary={
													<span className="text-[#ffffff] font-bold text-lg pl-6">
														Services
													</span>
												}
											/>
											{openServices ? (
												<ExpandLess className="text-[#ffffff] font-[700]" />
											) : (
												<ExpandMore className="text-[#ffffff] font-[700]" />
											)}
										</ListItemButton>
										<Collapse in={openServices} timeout={"auto"} unmountOnExit>
											<List
												component="div"
												disablePadding
												sx={{
													textAlign: "center",
													display: "flex",
													flexDirection: "column",
													justifyContent: "center",
													alignItems: "center",
													color: "white",
												}}
											>
												<ListItemButton>
													<ListItemText
														className="text-[#ffffff] font-medium text-lg text-center"
														primary="Clinic Management"
														onClick={(e) =>
															handleServiceClick("clinic-management")
														}
													/>
												</ListItemButton>
												<ListItemButton>
													<ListItemText
														className="text-[#ffffff] font-medium text-lg text-center"
														primary="Pharmacy Management"
														onClick={(e) =>
															handleServiceClick("pharmacy-management")
														}
													/>
												</ListItemButton>
												<ListItemButton>
													<ListItemText
														className="text-[#ffffff] font-medium text-lg text-center"
														primary="Hospital Management"
														onClick={(e) =>
															handleServiceClick("hospital-management")
														}
													/>
												</ListItemButton>
												<ListItemButton>
													<ListItemText
														className="text-[#ffffff] font-medium text-lg text-center"
														primary="Lab Management"
														onClick={(e) =>
															handleServiceClick("lab-management")
														}
													/>
												</ListItemButton>
												<ListItemButton>
													<ListItemText
														className="text-[#ffffff] font-medium text-lg text-center"
														primary="Claim/TPA Management"
														onClick={(e) =>
															handleServiceClick("claim-management")
														}
													/>
												</ListItemButton>
											</List>
										</Collapse>
									</List>
									{heads?.map((head) => (
										<li
											className="text-[#ffffff] font-medium text-lg text-center"
											key={head.id}
											onClick={() => {
												setOpen(!open);
												setOpenServices(false);
												handleDropClose();
											}}
										>
											<Link href={head.link}>{head.heading}</Link>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>

					{/* //lap screen  */}
					<ul className="text-[14px] xl:text-[16px] gap-2 xl:gap-4 font-medium hidden lg:flex h-full text-[#053C61] ">
						<li className="flex mb-1 justify-center items-center ">
							<Link
								className={`nav-link ${
									selectedTab === "/" ? "selected" : ""
								}  transition-all duration-300 ease-in-out`}
								onClick={() => setSelectedTab("/")}
								href="/"
							>
								Home
							</Link>
						</li>
						<li
							className=" flex flex-row items-center cursor-pointer h-full justify-center z-10 relative mt-[10px] mr-[-10px]"
							onClick={(e) => {
								e.stopPropagation();
								handleOpenMenu(e);
							}}
						>
							Services
							<IconButton
								onClick={(e) => {
									e.stopPropagation();
									handleOpenMenu(e);
								}}
								className="w-4 h-4"
							>
								{openMenu ? (
									<KeyboardArrowDownIcon />
								) : (
									<KeyboardArrowRightIcon />
								)}
							</IconButton>
							<Menu
								elevation={0}
								anchorEl={anchorEl}
								open={openMenu}
								onClose={handleCloseMenu}
								onClick={handleOpenMenu}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "center",
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "center",
								}}
								sx={{ top: "20px" }}
							>
								<Paper
									elevation={1}
									sx={{
										boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
										border: "1px solid rgba(0, 0, 0, 0.1)",
										paddingTop: "10px",
									}}
								>
									<MenuItem onClick={handleCloseMenu}>
										<Link
											href="/services/clinic-management"
											onClick={() => setSelectedTab("/services")}
										>
											Clinic Management
										</Link>
									</MenuItem>
									<MenuItem onClick={handleCloseMenu}>
										<Link
											href="/services/pharmacy-management"
											onClick={() => setSelectedTab("/services")}
										>
											Pharmacy Management
										</Link>
									</MenuItem>
									<MenuItem onClick={handleCloseMenu}>
										<Link
											href="/services/hospital-management"
											onClick={() => setSelectedTab("/services")}
										>
											Hospital Management
										</Link>
									</MenuItem>
									<MenuItem onClick={handleCloseMenu}>
										<Link
											href="/services/lab-management"
											onClick={() => setSelectedTab("/services")}
										>
											Lab Management
										</Link>
									</MenuItem>
									<MenuItem onClick={handleCloseMenu}>
										<Link
											href="/services/claim-management"
											onClick={() => setSelectedTab("/services")}
										>
											Claim/TPA Management
										</Link>
									</MenuItem>
								</Paper>
							</Menu>
						</li>
						{heads?.map((item, index) => {
							return (
								<li
									key={item.id}
									className={`nav-link ${
										selectedTab === item.link ? "selected" : ""
									} flex item-center  gap-1 justify-center `}
									onClick={() => setSelectedTab(item.link)}
								>
									<Link className="py-[4px]" href={item.link}>
										{item.heading}
									</Link>
								</li>
							);
						})}
					</ul>
				</div>

				<Box sx={{ width: "100%" }} className="block lg:hidden">
					<Box
						sx={{ bgcolor: "#053C61", width: "100%", height: "20px" }}
						className="flex justify-center items-center  py-4"
					>
						<IconButton aria-label="Call us">
							<PhoneIcon
								sx={{ color: "white", fontSize: { xs: "12px", md: "16px" } }}
							/>
						</IconButton>
						<a
							href="tel:+91 9509917266"
							className="text-[12px] text-white"
							target="_blank"
							aria-label="Call us at +91 9509917266"
						>
							Call us (+91 9509917266)
						</a>
					</Box>
				</Box>
			</Box>

			{/* 
			// schedule demo  */}
			{showButton && (
				<a
					href="https://outlook.office365.com/owa/calendar/ScheduleaDemo@vigorushealth.com/bookings/"
					aria-label="demo to schedule a meeting"
					target="_blank"
				>
					<Box
						className="bottom-20 hover:right-[2px] -right-[10rem] transition-all duration-500 ease-in-out"
						sx={{
							zIndex: 999,
							position: "fixed",
							color: "black",
							borderRadius: "50%",
						}}
					>
						<Box className="flex flex-row justify-evenly items-center w-[215px] hover:w-[250px] bg-gradient-to-r from-[#053C61] to-[#0A7BC7] text-white p-[16px] border rounded-full font-semibold transition-all duration-500 ease-in-out relative group">
							<Image
								src="/svg/chikitsa-schedule(white).svg"
								alt="chikitsa-checkbox"
								width={25}
								height={25}
							/>
							<Typography className="">Schedule a Demo</Typography>
						</Box>
					</Box>
				</a>
			)}

			{/* //WhatsAppIcon  */}
			<a href="https://wa.me/919509917266" target="_blank">
				<Box
					className="bottom-5 hover:right-[2px] -right-[12rem] transition-all duration-500 ease-in-out"
					sx={{
						zIndex: 999,
						position: "fixed",
						color: "black",
						borderRadius: "50%",
					}}
				>
					<Box className="flex flex-row bg-green-700 justify-evenly items-center w-[250px] hover:w-[300px] text-white p-[16px] border rounded-full font-semibold transition-all duration-500 ease-in-out relative group">
						<WhatsAppIcon />
						<Typography className="">Connect on Whatsapp</Typography>
					</Box>
				</Box>
			</a>

			<Box
				className="hidden sm:block"
				onClick={handleClickOpen}
				bgcolor="#053C61"
				sx={{
					zIndex: 2,
					position: "fixed",
					bottom: "30%",
					left: 0,
					color: "white",
				}}
			/>
			<Modal open={openDialogBox} onClose={handleClose}>
				<Box sx={style} width={!matches ? "90%" : matchesdownMd ? "80%" : null}>
					<IconButton
						aria-label="close"
						onClick={handleClose}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
					<Box sx={{ width: "100%", height: "100%" }}>
						<Grid container margin="auto" width={"100%"}>
							<Grid
								item
								xs={5}
								visibility={!matches ? "hidden" : null}
								display={!matches ? "none" : null}
								width="100%"
							>
								<Box
									className="bg-[url('/img/contactModalLeft.png')]"
									sx={{
										height: "100%",
										backgroundSize: "cover",
										backgroundRepeat: "no-repeat",
									}}
									display="flex"
									justifyContent="center"
									alignItems="center"
								>
									<Stack
										direction="column"
										gap={3}
										justifyContent="center"
										padding={5}
									>
										<Box>
											<Typography
												fontSize={25}
												fontWeight={900}
												color={"white"}
											>
												Let's Talk
											</Typography>
										</Box>
										<Stack direction="row" alignItems="center" gap={2}>
											<PhoneIcon sx={{ color: "white" }} />
											<Link
												style={{
													textDecoration: "none",
													color: "white",
													fontSize: 18,
												}}
												href="tel:+91-9509917266"
											>
												+91-9509917266
											</Link>
										</Stack>
										<Stack direction="row" alignItems="center" gap={2}>
											<WhatsAppIcon sx={{ color: "white" }} />
											<Link
												style={{
													textDecoration: "none",
													color: "white",
													fontSize: 18,
												}}
												target="_blank"
												href="https://wa.me/919509917266"
											>
												+91-9509917266
											</Link>
										</Stack>
									</Stack>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Modal>
		</React.Fragment>
	);
}

export default Header;
